import useSWR from "swr";
import useDealer from "./useDealer";
import DealerCatalogService from "services/DealerCatalogService";

const fetcher = (dealerId) => DealerCatalogService.getAll(dealerId).then((res) => res.data);

const useCatalogsSwr = () => {
    const { id: dealerId } = useDealer();

    const { data: catalogs = [], error, isLoading } = useSWR(
        dealerId ? `dealer_catalogs_${dealerId}` : null,
        () => fetcher(dealerId)
    );

    return {
        catalogs,
        error,
        isLoading,
    };
};

export default useCatalogsSwr;
