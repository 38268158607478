import React from "react";

// @material-ui/core components
import TextField from "@material-ui/core/TextField";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Paper, Typography } from "@material-ui/core";

export default function CreatePageTechMeta({ formik, languagesAvailable = "" }) {
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {/* English */}
                {languagesAvailable.includes("en") && (
                    <Paper elevation={2} style={{ padding: 16, marginBottom: 24 }}>
                        <Typography variant="h6" gutterBottom>
                            🇬🇧 English
                        </Typography>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    margin="dense"
                                    id="enPageTitle"
                                    name="enPageTitle"
                                    label="EN title"
                                    value={formik.values.enPageTitle}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.enPageTitle &&
                                        Boolean(formik.errors.enPageTitle)
                                    }
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    margin="dense"
                                    id="enMetaTitle"
                                    name="enMetaTitle"
                                    label="EN meta title"
                                    value={formik.values.enMetaTitle}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.enMetaTitle &&
                                        Boolean(formik.errors.enMetaTitle)
                                    }
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="enMetaDescription"
                                    name="enMetaDescription"
                                    label="EN meta description"
                                    value={formik.values.enMetaDescription}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.enMetaDescription &&
                                        Boolean(formik.errors.enMetaDescription)
                                    }
                                    inputProps={{ maxLength: 5000 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="enMetaKeywords"
                                    name="enMetaKeywords"
                                    label="EN meta keywords"
                                    value={formik.values.enMetaKeywords}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.enMetaKeywords &&
                                        Boolean(formik.errors.enMetaKeywords)
                                    }
                                    inputProps={{ maxLength: 5000 }}
                                />
                            </GridItem>
                        </GridContainer>
                    </Paper>
                )}

                {/* Spanish */}
                {languagesAvailable.includes("es") && (
                    <Paper elevation={2} style={{ padding: 16, marginBottom: 24 }}>
                        <Typography variant="h6" gutterBottom>
                            🇪🇸 Spanish
                        </Typography>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="esPageTitle"
                                    name="esPageTitle"
                                    label="ES title"
                                    value={formik.values.esPageTitle}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.esPageTitle &&
                                        Boolean(formik.errors.esPageTitle)
                                    }
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="esMetaTitle"
                                    name="esMetaTitle"
                                    label="ES meta title"
                                    value={formik.values.esMetaTitle}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.esMetaTitle &&
                                        Boolean(formik.errors.esMetaTitle)
                                    }
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="esMetaDescription"
                                    name="esMetaDescription"
                                    label="ES meta content"
                                    value={formik.values.esMetaDescription}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.esMetaDescription &&
                                        Boolean(formik.errors.esMetaDescription)
                                    }
                                    inputProps={{ maxLength: 5000 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="esMetaKeywords"
                                    name="esMetaKeywords"
                                    label="ES meta keywords"
                                    value={formik.values.esMetaKeywords}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.esMetaKeywords &&
                                        Boolean(formik.errors.esMetaKeywords)
                                    }
                                    inputProps={{ maxLength: 5000 }}
                                />
                            </GridItem>
                        </GridContainer>
                    </Paper>
                )}

                {/* Portugués */}
                {languagesAvailable.includes("pt") && (
                    <Paper elevation={2} style={{ padding: 16, marginBottom: 24 }}>
                        <Typography variant="h6" gutterBottom>
                            🇵🇹 Portuguese
                        </Typography>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="ptPageTitle"
                                    name="ptPageTitle"
                                    label="PT title"
                                    value={formik.values.ptPageTitle}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.ptPageTitle &&
                                        Boolean(formik.errors.ptPageTitle)
                                    }
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="ptMetaTitle"
                                    name="ptMetaTitle"
                                    label="PT meta title"
                                    value={formik.values.ptMetaTitle}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.ptMetaTitle &&
                                        Boolean(formik.errors.ptMetaTitle)
                                    }
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="ptMetaDescription"
                                    name="ptMetaDescription"
                                    label="PT meta content"
                                    value={formik.values.ptMetaDescription}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.ptMetaDescription &&
                                        Boolean(formik.errors.ptMetaDescription)
                                    }
                                    inputProps={{ maxLength: 5000 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="ptMetaKeywords"
                                    name="ptMetaKeywords"
                                    label="PT meta keywords"
                                    value={formik.values.ptMetaKeywords}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.ptMetaKeywords &&
                                        Boolean(formik.errors.ptMetaKeywords)
                                    }
                                    inputProps={{ maxLength: 5000 }}
                                />
                            </GridItem>
                        </GridContainer>
                    </Paper>
                )}

                {/* Francés */}
                {languagesAvailable.includes("fr") && (
                    <Paper elevation={2} style={{ padding: 16, marginBottom: 24 }}>
                        <Typography variant="h6" gutterBottom>
                            🇫🇷 French
                        </Typography>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="frPageTitle"
                                    name="frPageTitle"
                                    label="FR title"
                                    value={formik.values.frPageTitle}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.frPageTitle &&
                                        Boolean(formik.errors.frPageTitle)
                                    }
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="frMetaTitle"
                                    name="frMetaTitle"
                                    label="FR meta title"
                                    value={formik.values.frMetaTitle}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.frMetaTitle &&
                                        Boolean(formik.errors.frMetaTitle)
                                    }
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="frMetaDescription"
                                    name="frMetaDescription"
                                    label="FR meta content"
                                    value={formik.values.frMetaDescription}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.frMetaDescription &&
                                        Boolean(formik.errors.frMetaDescription)
                                    }
                                    inputProps={{ maxLength: 5000 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="frMetaKeywords"
                                    name="frMetaKeywords"
                                    label="FR meta keywords"
                                    value={formik.values.frMetaKeywords}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.frMetaKeywords &&
                                        Boolean(formik.errors.frMetaKeywords)
                                    }
                                    inputProps={{ maxLength: 5000 }}
                                />
                            </GridItem>
                        </GridContainer>
                    </Paper>
                )}
            </GridItem>
        </GridContainer>
    );
}
