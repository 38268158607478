import React from "react";

// @material-ui/core components
import { TextField, Paper, Typography, MenuItem } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HtmlEditor from "components/HtmlEditor/HtmlEditor";

export default function CreatePageTechCont({ formik, languagesAvailable = "" }) {
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            margin="dense"
                            id="pagetechPosition"
                            name="pagetechPosition"
                            label="Page tech position"
                            value={formik.values.pagetechPosition}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.pagetechPosition &&
                                Boolean(formik.errors.pagetechPosition)
                            }
                        >
                            <MenuItem value={0}>Top</MenuItem>
                            {/*<MenuItem value={5}>Middle</MenuItem>*/}
                            <MenuItem value={10}>Bottom</MenuItem>
                        </TextField>
                    </GridItem>
                </GridContainer>

                {/* English */}
                {languagesAvailable.includes("en") && (
                    <Paper elevation={2} style={{ padding: 16, marginBottom: 24, marginTop: 10 }}>
                        <Typography variant="h6" gutterBottom>
                            🇬🇧 English
                        </Typography>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="enTitle"
                                    name="enTitle"
                                    label="English title"
                                    value={formik.values.enTitle}
                                    onChange={formik.handleChange}
                                    error={formik.touched.enTitle && Boolean(formik.errors.enTitle)}
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <HtmlEditor
                                    title="English HTML"
                                    data={formik.values.enHtml}
                                    onBlur={(value) => formik.setFieldValue("enHtml", value)}
                                />
                            </GridItem>
                        </GridContainer>
                    </Paper>
                )}

                {/* Spanish */}
                {languagesAvailable.includes("es") && (
                    <Paper elevation={2} style={{ padding: 16, marginBottom: 24 }}>
                        <Typography variant="h6" gutterBottom>
                            🇪🇸 Spanish
                        </Typography>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="esTitle"
                                    name="esTitle"
                                    label="Spanish title"
                                    value={formik.values.esTitle}
                                    onChange={formik.handleChange}
                                    error={formik.touched.esTitle && Boolean(formik.errors.esTitle)}
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <HtmlEditor
                                    title="Spanish HTML"
                                    data={formik.values.esHtml}
                                    onBlur={(value) => formik.setFieldValue("esHtml", value)}
                                />
                            </GridItem>
                        </GridContainer>
                    </Paper>
                )}

                {/* Portugués */}
                {languagesAvailable.includes("pt") && (
                    <Paper elevation={2} style={{ padding: 16, marginBottom: 24 }}>
                        <Typography variant="h6" gutterBottom>
                            🇵🇹 Portuguese
                        </Typography>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="ptTitle"
                                    name="ptTitle"
                                    label="Portuguese title"
                                    value={formik.values.ptTitle}
                                    onChange={formik.handleChange}
                                    error={formik.touched.ptTitle && Boolean(formik.errors.ptTitle)}
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <HtmlEditor
                                    title="Portuguese HTML"
                                    data={formik.values.ptHtml}
                                    onBlur={(value) => formik.setFieldValue("ptHtml", value)}
                                />
                            </GridItem>
                        </GridContainer>
                    </Paper>
                )}

                {/* French */}
                {languagesAvailable.includes("fr") && (
                    <Paper elevation={2} style={{ padding: 16, marginBottom: 24 }}>
                        <Typography variant="h6" gutterBottom>
                            🇫🇷 French
                        </Typography>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="frTitle"
                                    name="frTitle"
                                    label="French title"
                                    value={formik.values.frTitle}
                                    onChange={formik.handleChange}
                                    error={formik.touched.frTitle && Boolean(formik.errors.frTitle)}
                                    inputProps={{ maxLength: 512 }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <HtmlEditor
                                    title="French HTML"
                                    data={formik.values.frHtml}
                                    onBlur={(value) => formik.setFieldValue("frHtml", value)}
                                />
                            </GridItem>
                        </GridContainer>
                    </Paper>
                )}
            </GridItem>
        </GridContainer>
    );
}
